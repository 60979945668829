
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';

@import "src/assets/scss/colors";
@import "src/assets/scss/variables-demo";
@import "src/assets/scss/font";
@import "src/assets/scss/kendo-grid.scss";
@import "src/assets/scss/buttons.scss";
@import "src/assets/scss/ngx-timepicker.scss";
// @import "src/assets/scss/kendo.bootstrap-v4.scss";
// ::ng-deep {
  // .k-grid {
  //     border: none !important;
  // }
// }
// Plus imports for other components in your app.

@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600&display=swap);

// Define a custom mixin that takes in the current theme
@mixin theme-color-grabber($theme) {
  // Parse the theme and create variables for each color in the pallete
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  // Create theme specfic styles
  .primaryColorBG {
    background-color: mat-color($primary);
  }
  .accentColorBG {
    background-color: mat-color($accent);
  }
  .warnColorBG {
    background-color: mat-color($warn);
  }

  .primaryColor {
    color: mat-color($primary) !important;

    mat-icon {
      // color: mat-color($primary) !important;
    }
  }

  .primaryHoverColor {
    &:hover {
      color: mat-color($primary) !important;
    }
  }

  .primaryBorderColor {
    border-color: mat-color($primary) !important;
  }

  .primaryColor35 {
    color: rgba( mat-color($primary), 0.35 );
  }

  .primaryColorBackground35 {
    background-color: rgba( mat-color($primary), 0.05 );
  }

  .clickable-icon {
    cursor: pointer;

    &:hover {
      color: mat-color($primary) !important;
    }
  }

  .bordered-icon{
    cursor: pointer;

    &:hover {
      color: white;
      text-shadow: -1px 0 mat-color($primary), 0 1px mat-color($primary), 1px 0 mat-color($primary), 0 -1px mat-color($primary);
    }
  }

  .clickable-text {
    cursor: pointer;
  
    &:hover {
      color: mat-color($primary) !important;
      text-decoration: underline;
    }
  }

  .mat-stroked-button {
    // border-color: mat-color($primary) !important;
    border-color: inherit !important;
    // &:hover {
    //   background-color: mat-color($primary) !important;
    //   color: white !important;
    // }
  }

  .mat-card-title {
    button {
      margin-left: 10px !important;
    }
  }

  app-data-grid-comp {
    .row-action-button {
      background-color: transparent !important;
      box-shadow: none !important;

      &:hover {
        mat-icon {
          color: mat-color($primary) !important;
        }
      }
    }
  }
}

$custom-typography: mat-typography-config(
  $font-family: "Open Sans, sans-serif"
);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core($custom-typography);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$angular-material-admin-primary: mat-palette($mat-indigo);
$angular-material-admin-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$angular-material-admin-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$angular-material-admin-theme: mat-light-theme($angular-material-admin-primary, $angular-material-admin-accent, $angular-material-admin-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($angular-material-admin-theme);



$ama-yellow: (
  50: $orange,
  100: #ECECEC,
  200: $orange,
  300: $orange,
  400: $orange,
  500: $orange,
  600: $orange,
  700: $orange,
  800: $orange,
  900: $orange,
  A100: $orange,
  A200: $orange,
  A400: $orange,
  A700: $orange,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$ama-pink: (
  50: $pink,
  100: #ECECEC,
  200: $pink,
  300: $pink,
  400: $pink,
  500: $pink,
  600: $pink,
  700: $pink,
  800: $pink,
  900: $pink,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$ama-blue: (
  50: $hubbub-primary,
  100: #ECECEC,
  200: $hubbub-primary,
  300: $hubbub-primary,
  400: $hubbub-primary,
  500: $hubbub-primary,
  600: $hubbub-primary,
  700: $hubbub-primary,
  800: $hubbub-primary,
  900: $hubbub-primary,
  A100: $hubbub-primary,
  A200: $hubbub-primary,
  A400: $hubbub-primary,
  A700: $hubbub-primary,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$ama-primary: mat-palette($ama-blue);
$ama-accent:  mat-palette($ama-yellow);
$ama-warn:    mat-palette($ama-pink);

$ama-theme: mat-light-theme($ama-primary, $ama-accent, $ama-warn);

@include theme-color-grabber($ama-theme);
@include angular-material-theme($ama-theme);

/*
==========================================================================
SightSavers start
==========================================================================
*/

/* For use in src/lib/core/theming/_palette.scss */
$sight-savers-raspberry: (
    50 : #f2e0ea,
    100 : #e0b3cb,
    200 : #cb80a8,
    300 : #b64d85,
    400 : #a6266b,
    500 : #960051,
    600 : #8e004a,
    700 : #830040,
    800 : #790037,
    900 : #680027,
    A100 : #ff97b5,
    A200 : #ff6490,
    A400 : #ff316c,
    A700 : #ff1859,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$sight-savers-yellow: (
    50 : #fff7e4,
    100 : #ffebbd,
    200 : #ffdd91,
    300 : #ffcf64,
    400 : #ffc543,
    500 : #ffbb22,
    600 : #ffb51e,
    700 : #ffac19,
    800 : #ffa414,
    900 : #ff960c,
    A100 : #ffffff,
    A200 : #fffbf6,
    A400 : #ffe3c3,
    A700 : #ffd7a9,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$sight-savers-tomato: (
    50 : #f5e5e6,
    100 : #e7bec1,
    200 : #d79397,
    300 : #c7686d,
    400 : #bb474e,
    500 : #af272f,
    600 : #a8232a,
    700 : #9f1d23,
    800 : #96171d,
    900 : #860e12,
    A100 : #ffb7b9,
    A200 : #ff8487,
    A400 : #ff5155,
    A700 : #ff373c,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #ffffff,
    )
);

$sight-savers-primary: mat-palette($sight-savers-raspberry);
$sight-savers-accent:  mat-palette($sight-savers-yellow);
$sight-savers-warn:    mat-palette($sight-savers-tomato);
$sight-savers-theme:   mat-light-theme((
  color: (
    primary: $sight-savers-primary,
    accent: $sight-savers-accent,
    warn: $sight-savers-warn,
  )
));

.sightSavers {
  @include theme-color-grabber($sight-savers-theme);
  @include angular-material-color($sight-savers-theme);
}

/*
==========================================================================
SightSavers end
==========================================================================
*/

/*
==========================================================================
Northern Graphite start
==========================================================================
*/

/* For use in src/lib/core/theming/_palette.scss */
$md-northern-graphite-blue: (
  50 : #e5efff,
  100 : #bfd7ff,
  200 : #94bcff,
  300 : #69a1ff,
  400 : #488dff,
  500 : #2879ff,
  600 : #2471ff,
  700 : #1e66ff,
  800 : #185cff,
  900 : #0f49ff,
  A100 : #ffffff,
  A200 : #f7f9ff,
  A400 : #c4d0ff,
  A700 : #abbcff,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #000000,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #000000,
  )
);

$md-northern-graphite-fresh-green: (
  50 : #f2fae0,
  100 : #dff2b3,
  200 : #c9ea80,
  300 : #b3e24d,
  400 : #a3db26,
  500 : #93d500,
  600 : #8bd000,
  700 : #80ca00,
  800 : #76c400,
  900 : #64ba00,
  A100 : #f0ffe3,
  A200 : #d5ffb0,
  A400 : #bbff7d,
  A700 : #adff63,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #000000,
      500 : #000000,
      600 : #000000,
      700 : #000000,
      800 : #000000,
      900 : #000000,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #000000,
  )
);

$md-northern-graphite-graphite: (
    50 : #e9ebec,
    100 : #c8ced0,
    200 : #a4adb1,
    300 : #808c92,
    400 : #64747a,
    500 : #495b63,
    600 : #42535b,
    700 : #394951,
    800 : #314047,
    900 : #212f35,
    A100 : #7ed4ff,
    A200 : #4bc3ff,
    A400 : #18b2ff,
    A700 : #00a9fd,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$northern-graphite-primary: mat-palette($md-northern-graphite-blue);
$northern-graphite-accent:  mat-palette($md-northern-graphite-fresh-green);
$northern-graphite-warn:    mat-palette($md-northern-graphite-graphite);
$northern-graphite-theme:   mat-light-theme((
  color: (
    primary: $northern-graphite-primary,
    accent: $northern-graphite-accent,
    warn: $northern-graphite-warn,
  )
));

.northernGraphite {
  @include theme-color-grabber($northern-graphite-theme);
  @include angular-material-color($northern-graphite-theme);
}

/*
==========================================================================
Northern Graphite end
==========================================================================
*/

/*
==========================================================================
B2Gold start
==========================================================================
*/

/* For use in src/lib/core/theming/_palette.scss */
$md-b2gold-gold: (
    50 : #fbf6e1,
    100 : #f6e9b5,
    200 : #f0da84,
    300 : #e9cb53,
    400 : #e5bf2e,
    500 : #e0b409,
    600 : #dcad08,
    700 : #d8a406,
    800 : #d39c05,
    900 : #cb8c02,
    A100 : #fffbf3,
    A200 : #ffe9c0,
    A400 : #ffd78d,
    A700 : #ffce74,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-b2gold-fresh-green: (
    50 : #f1f8e8,
    100 : #ddeec5,
    200 : #c6e39f,
    300 : #afd779,
    400 : #9ecf5c,
    500 : #8dc63f,
    600 : #85c039,
    700 : #7ab931,
    800 : #70b129,
    900 : #5da41b,
    A100 : #ebffdb,
    A200 : #cfffa8,
    A400 : #b3ff75,
    A700 : #a5ff5c,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-b2gold-graphite: (
    50 : #e9ebec,
    100 : #c8ced0,
    200 : #a4adb1,
    300 : #808c92,
    400 : #64747a,
    500 : #495b63,
    600 : #42535b,
    700 : #394951,
    800 : #314047,
    900 : #212f35,
    A100 : #7ed4ff,
    A200 : #4bc3ff,
    A400 : #18b2ff,
    A700 : #00a9fd,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$b2gold-primary: mat-palette($md-b2gold-gold);
$b2gold-accent:  mat-palette($md-b2gold-gold);
$b2gold-warn:    mat-palette($md-b2gold-graphite);
$b2gold-theme:   mat-light-theme((
  color: (
    primary: $b2gold-primary,
    accent: $b2gold-accent,
    warn: $b2gold-warn,
  )
));

.b2gold {
  @include theme-color-grabber($b2gold-theme);
  @include angular-material-color($b2gold-theme);
}

/*
==========================================================================
B2Gold end
==========================================================================
*/

/* 
==========================================================================
The Link MB start
========================================================================== 
*/

/* For use in src/lib/core/theming/_palette.scss */
$md-the-link-mb-primary: (
  50 : #e7f6e9,
  100 : #c4e9c9,
  200 : #9cdaa5,
  300 : #74cb80,
  400 : #57c065,
  500 : #39b54a,
  600 : #33ae43,
  700 : #2ca53a,
  800 : #249d32,
  900 : #178d22,
  A100 : #c3ffc8,
  A200 : #90ff99,
  A400 : #5dff6a,
  A700 : #44ff52,
  contrast: (
      50 : #ffffff,
      100 : #ffffff,
      200 : #ffffff,
      300 : #ffffff,
      400 : #ffffff,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #ffffff,
      A200 : #ffffff,
      A400 : #ffffff,
      A700 : #ffffff,
  )
);

$md-the-link-mb-accent: (
  50 : #e1f5fd,
  100 : #b3e7fa,
  200 : #80d7f7,
  300 : #4dc6f4,
  400 : #27baf1,
  500 : #01aeef,
  600 : #01a7ed,
  700 : #019deb,
  800 : #0194e8,
  900 : #0084e4,
  A100 : #ffffff,
  A200 : #d8ecff,
  A400 : #a5d4ff,
  A700 : #8bc8ff,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #000000,
      500 : #000000,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #000000,
  )
);

$md-the-link-mb-warn: (
    50 : #f7f2e1,
    100 : #ecdfb5,
    200 : #e0c984,
    300 : #d3b352,
    400 : #c9a32d,
    500 : #c09308,
    600 : #ba8b07,
    700 : #b28006,
    800 : #aa7604,
    900 : #9c6402,
    A100 : #ffe8c7,
    A200 : #ffd294,
    A400 : #ffbd61,
    A700 : #ffb347,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$the-link-mb-primary: mat-palette($md-the-link-mb-primary);
$the-link-mb-accent:  mat-palette($md-the-link-mb-accent);
$the-link-mb-warn:    mat-palette($md-the-link-mb-warn);
$the-link-mb-theme:   mat-light-theme((
  color: (
    primary: $the-link-mb-primary,
    accent: $the-link-mb-accent,
    warn: $the-link-mb-warn,
  )
));

.theLinkMbTheme {
  @include theme-color-grabber($the-link-mb-theme);
  @include angular-material-color($the-link-mb-theme);
}

/* 
==========================================================================
The Link MB end
========================================================================== 
*/


/* 
==========================================================================
Christmas start
========================================================================== 
*/

/* For use in src/lib/core/theming/_palette.scss */
$md-christmasprimary: (
    50 : #e5f4f0,
    100 : #bfe5da,
    200 : #94d3c1,
    300 : #69c1a8,
    400 : #49b496,
    500 : #29a783,
    600 : #249f7b,
    700 : #1f9670,
    800 : #198c66,
    900 : #0f7c53,
    A100 : #aeffde,
    A200 : #7bffc9,
    A400 : #48ffb4,
    A700 : #2fffaa,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-christmasaccent: (
    50 : #f8e1e2,
    100 : #efb4b6,
    200 : #e48286,
    300 : #d95055,
    400 : #d02b30,
    500 : #c8050c,
    600 : #c2040a,
    700 : #bb0408,
    800 : #b40306,
    900 : #a70103,
    A100 : #ffd1d1,
    A200 : #ff9e9e,
    A400 : #ff6b6b,
    A700 : #ff5252,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-christmaswarn: (
    50 : #f7f2e1,
    100 : #ecdfb5,
    200 : #e0c984,
    300 : #d3b352,
    400 : #c9a32d,
    500 : #c09308,
    600 : #ba8b07,
    700 : #b28006,
    800 : #aa7604,
    900 : #9c6402,
    A100 : #ffe8c7,
    A200 : #ffd294,
    A400 : #ffbd61,
    A700 : #ffb347,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$christmas-primary: mat-palette($md-christmasprimary);
$christmas-accent:  mat-palette($md-christmasaccent);
$christmas-warn:    mat-palette($md-christmaswarn);
$christmas-theme:   mat-light-theme((
  color: (
    primary: $christmas-primary,
    accent: $christmas-accent,
    warn: $christmas-warn,
  )
));

.christmasTheme {
  @include theme-color-grabber($christmas-theme);
  @include angular-material-color($christmas-theme);
}

/* 
==========================================================================
Christmas end
========================================================================== 
*/

button.mat-menu-item {
  font-size: 16px;
}

button.mat-menu-item:hover:not([disabled]), .mat-menu-item.cdk-program-focused:not([disabled]), .mat-menu-item.cdk-keyboard-focused:not([disabled]), .mat-menu-item-highlighted:not([disabled]) {
  font-size: 16px;
}

.mat-option {
  font-size: 16px;
  height: 36px;
  padding: 6px 16px;
  color: $dark-grey;

  &.mat-focus-indicator.mat-selected.mat-active {
    background-color: $blue-white;
    color: $dark-grey;
  }

  &:hover:not(.mat-option-disabled) {
    background-color: $blue-white;
  }
}

.mat-form-field-underline {
  display: none;
}

html, body { height: 100%; }
body { margin: 0;}

.mat-h1, .mat-headline, .mat-typography h1{
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 48px;
}

.mat-h2, .mat-headline, .mat-typography h2{
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 36px;
}

.mat-h3, .mat-headline, .mat-typography h3{
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 26px;
}

.mat-h4, .mat-headline, .mat-typography h4{
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 24px;
}

.mat-h5, .mat-headline, .mat-typography h5{
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 21px;
}

.mat-h6, .mat-headline, .mat-typography h6{
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 18px;
}

.mat-body p, .mat-body-1 p, .mat-typography p {
  margin-bottom: 16px;
  line-height: normal;
}

button.mat-fab .mat-button-wrapper {
  padding: 0;
}

.mat-card {
  color: $card-font-color;
  margin: 16px;
}

mat-card .header {
  color: $grey;
  display: flex;
  justify-content: space-between;
  // padding: 24px 24px 8px;
  margin-bottom: 0;
}

.mat-card-title p {
  font-size: 21px;
  font-weight: 400;
  color: $card-title-font-color;
}

mat-toolbar.page-header {
  display: flex;
  justify-content: space-between;
  padding: 50px 24px 16px 24px;
  background: none;
  height: auto;
  color: $toolbar-title-font-color;

  @media (max-width: $small) {
    flex-direction: column;
    align-items: baseline;
  }
}

.mat-toolbar h1 {
  font-weight: 400;
  font-size: 38.4px;
  color: $toolbar-title-font-color;
  line-height: 1;
  letter-spacing: -0.2186px;

  @media (max-width: $small) {
    white-space: normal;
  }
}

.mat-toolbar button {
  height: 41.6px;
  width: auto;
  line-height: 1.75;
  font-size: 0.9375rem;
  font-weight: 400;

  @media (max-width: $small) {
    margin-top: 16px;
  }
}

mat-tab-header .mat-tab-label, .mat-tab-link {
  text-transform: uppercase;
  // color: $tabs-header-font-color;
  font-weight: $fw-normal;
  // width: 50%;
  // min-width: 0;
  // padding: 0;
  // opacity: 1;
}

::ng-deep mat-tab-header .mat-tab-label-active {
  color: $dark-grey !important;
}

::ng-deep mat-tab-header .mat-tab-label, .mat-tab-link {
  color: $dark-grey !important;
}

// mat-tab-body div.mat-tab-body-content {
  // overflow: hidden;
// }

.toast-top-center {
  top: 76px;
}

.toast-top-left {
  top: 76px;
}

.toast-top-right {
  top: 76px;
}

.toast-bottom-center {
  bottom: 12px;
}

.toast-container .ngx-toastr {
  // padding: 50px;
  border-radius: 45px;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px $light-grey;
}

.mat-icon {
  padding-right: 3px;
  padding-bottom: 5px;
}

.material-icons {
  font-size: 28px;
}

button.mat-mini-fab .mat-button-wrapper {
  padding: 0;
}

button.mat-mini-fab {
  height: 36px;
  width: 36px;
}

div.mat-menu-panel {
  margin-top: 20px;
}

.fa {
  font-size: 1.5em;
}

.apexcharts-xaxistooltip.apexcharts-xaxistooltip-bottom.apexcharts-theme-light {
  background-color: $chart-tooltip-background-color;
  color: $chart-tooltip-font-color;
  border: none;
}

div.apexcharts-xaxistooltip-bottom:after {
  border-bottom-color: $chart-tooltip-border-color;
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.2);
}

.custom-modalbox{
  text-align: center;

  .dialog-text {
      color: $card-title-font-color;
      font-size: $fs-medium;
      font-weight: $fw-lighter;
    }

    button {
      margin: 5px;

      height: 41.6px;
      width: auto;
      line-height: 1.75;
      font-size: 0.9375rem;
      font-weight: 400;

      box-sizing: border-box;
      position: relative;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      cursor: pointer;
      outline: none;
      border: none;
      -webkit-tap-highlight-color: transparent;
      display: inline-block;
      white-space: nowrap;
      text-decoration: none;
      vertical-align: baseline;
      text-align: center;
      margin: 0;
      min-width: 64px;
      line-height: 36px;
      padding: 0 16px;
      border-radius: 4px;
      overflow: visible;

      @media (max-width: $small) {
        margin-top: 16px;
      }

      &.warn {
        color: white;
        background-color: $pink;
      }


    }

    .mat-dialog-actions {
      // align buttons to right as shown in Material Design guide
      justify-content: center;

    .mat-button {
      &:not(:first-child) {
          margin-left: 1em;
        }
      }
    }

    .flex-spacer {
      flex: 1 1 auto;
    }


}

mat-dialog-actions {
  float: right;
}

.toggle-container {
  margin-bottom: 10px;

  label {
      margin-right: 10px;
  }
}

// Link styling
.navigation-link {
  color: $blue !important;
  text-decoration: none !important;
  cursor: pointer !important;

  &:hover {
    text-decoration: underline !important;
    color: $blue !important;
  }
}

// Chips Styling
.green-chip {
  background-color: $green !important;
  color: white !important;
}

.red-chip {
  background-color: $red !important;
  color: white !important;
}

.yellow-chip {
  background-color: $yellow !important;
  color: black !important;
}

.orange-chip {
  background-color: $orange !important;
  color: white !important;
}

.blue-chip {
  background-color: $light-blue !important;
  color: white !important;
}

.primary-chip {
  background-color: $hubbub-primary !important;
  color: white !important;
}

.blue-chip {
  background-color: $blue !important;
  color: white !important;
}

// ngx-material-timepicker display over dialog
.timepicker-overlay {
  z-index: 1100 !important;
}

.timepicker-backdrop-overlay {
  z-index: 1100 !important;
}

// This is global styling for the page description
.page-description-section {
  color: $sidebar-font-color;
  padding: 15px;
  border: solid 2px $gray-015;
  margin-bottom: 20px;
}


::-webkit-scrollbar { width: 8px; height: 8px;}
::-webkit-scrollbar-button { display: none }
::-webkit-scrollbar-track {  background-color: $scrollbar-track-color;}
::-webkit-scrollbar-track-piece { background-color: $scrollbar-track-piece-color;}
::-webkit-scrollbar-thumb { height: 50px; background-color: $light-grey; border-radius: 36px;}
::-webkit-scrollbar-corner { background-color: $scrollbar-corner-color;}
// ::-webkit-resizer { background-color: $dark-grey;}

// Kendo grid loading animation START

.k-loading-color {
    background: linear-gradient(270deg, #a8a8a8, #f0f0f0);
    background-size: 400% 400%;
    opacity: .6;

    -webkit-animation: AnimationName 3s ease infinite;
    -moz-animation: AnimationName 3s ease infinite;
    animation: AnimationName 3s ease infinite;
}

@-webkit-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}

// Kendo grid loading animation END