// .k-grid .k-grid-md .k-table-th > .k-cell-inner > .k-link, .k-grid-md .k-table-th > .k-cell-inner > .k-link {
//     padding-block: 15px !important;
// }

// .k-grid .k-grid-md .k-table-th, .k-grid-md .k-table-th {
//     padding-block: 15px !important;
// }

// .k-grid-header, .k-grid-header-wrap, .k-grouping-header, .k-grid .k-table-th, .k-grid td, .k-grid .k-table-td, .k-grid-footer, .k-grid-footer-wrap, .k-grid-content-locked, .k-grid-footer-locked, .k-grid-header-locked, .k-filter-row > .k-table-th, .k-filter-row > td, .k-filter-row > .k-table-td {
//     border-color: transparent !important;
// }

// .k-toolbar {
//     background-color: transparent !important;
//     align-items: baseline;
// }

// .k-pager {
//     background-color: transparent !important;
// }

// .k-grid {
//     border: none !important;
// }

// .k-grid-toolbar {
//     border-color: rgba(0, 0, 0, 0.08) !important;
// }

// .k-grid-pager {
//     border-color: rgba(0, 0, 0, 0.08) !important;
// }

// tr.clickable {
//     cursor: pointer;
// }

// .k-checkbox {
//     border-color: rgba(0, 0, 0, 0.5) !important;
// } 

// .k-grid .k-table-row.k-table-alt-row {
//     background-color: inherit !important;
//     border-top: solid 1px rgba(0, 0, 0, 0.5);
//     border-bottom: solid 1px rgba(0, 0, 0, 0.5);

//     &:hover {
//         background-color: inherit;
//     }
// }




tr {
    border-top: solid 1px rgba(0, 0, 0, 0.08);
}

.k-grid {
    border: none !important;

    .k-toolbar {
        background-color: transparent;
        align-items: baseline;
    }

    // If you have a form field field remove the padding
    .mat-form-field-wrapper {
        padding-bottom: 0px !important;
    }

    .k-grid-header {
        border-bottom: 1px solid;
    }

    th {
        border-left: none;
        padding: 20px;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-transform: uppercase;
    }

    tr.clickable {
        cursor: pointer;

        &:hover {
            background-color: $hubbub-primary-02;
        }
    }

    td {
        border-left: none;
        padding: 20px;
        border-right: none;
        border-right-width: 0 !important;
    }

    .k-pager {
        background-color: transparent;
    }
    
    .k-grid-pager {
        border-top: none;
    }

    .k-grid-header-sticky, .k-grid .k-grid-content-sticky, .k-grid .k-grid-footer-sticky {
        border-right: none;
    }

    .k-checkbox {
        border-color: rgba(0, 0, 0, 0.5);
    }    
}